import React from "react";

import PreviewCompatibleImage from "./utilities/PreviewCompatibleImage";
import RenderMarkdown from "./utilities/RenderMarkdown";

const Pitch = ({ pitchObject }) => {
  return (
    <>
      <RenderMarkdown
        markdownContent={pitchObject.title}
        whitespace="normal"
        className="space-y-2 sm:space-y-4"
      />
      <div className="flex justify-center">
        <PreviewCompatibleImage
          imageObject={pitchObject.imageObject}
          className="max-w-3xl"
        />
      </div>
      <RenderMarkdown
        markdownContent={pitchObject.description}
        whitespace="normal"
      />
    </>
  );
};

export default Pitch;
